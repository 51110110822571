import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function OpenFile({ fileName }) {
  useEffect(() => {
    if (fileName) {
      window.location.href = `${process.env.PUBLIC_URL}/${fileName}`;
    }
  }, [fileName]);

  return null;
}

export default OpenFile;