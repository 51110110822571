import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/bottomBar/BottomBar.css';

const BottomBar = () => {
    const handleOpenTerms = () => {
        window.open(`${process.env.PUBLIC_URL}/terms.pdf`, '_blank');
    };

    return (
        <nav className="bottom">
            <div className="bottom-logo">
                <img src="/logo-default.svg" alt="Shape Factor Logo" className="logo" />
            </div>
            <div>
                <p className='text-xs'>© SHAPEFACTOR 2024. Wszystkie prawa zastrzeżone</p>
            </div>
            <div className="bottom-links">
                <p className='text-xs'>
                    Cookies
                </p>
                <p
                    className="text-xs"
                    onClick={handleOpenTerms}
                >
                    Polityka prywatności
                </p>
                <p
                    className="text-xs"
                    onClick={handleOpenTerms}
                >
                    Regulamin
                </p>
            </div>
        </nav>
    );
};


export default BottomBar


